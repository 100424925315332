import React from "react";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <>
      <div className="page-wrapper page-wrapper-flex">
        <div
          data-w-id="633c7290-841f-f768-cc10-d7d9aa0a9b21"
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="header-wrapper w-nav"
        >
          <div className="container-default w-container">
            <div
              data-w-id="633c7290-841f-f768-cc10-d7d9aa0a9b23"
              className="header-content-wrapper flex-horizontal"
            >
              <Link to="#" className="header-logo-link center w-nav-brand">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63e68a8b717bf4b59a5452de_logo-cloudly-x-webflow-template.png"
                  alt="Tej Life - Logo"
                  className="header-logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="section hero coming-soon-hero">
          <div className="container-default position-relative w-container">
            <div
              data-w-id="ea94fae7-c17b-caae-7783-1fea3b4d89ab"
              className="shape-wrapper md:style-1"
            >
              <div className="shape size-130px top-left">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd58c617fdf7e8f8f0dbef_face-with-open-eyes-and-hand-over-mouth-cloudly-x-webflow-templates.png"
                  alt=""
                  className="moving-item-two"
                />
              </div>
              <div className="shape size-150px bottom-left">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd58d7d24abc789d8e4756_monkey-with-hands-over-eyes-cloudly-x-webflow-templates.png"
                  alt=""
                  className="blur-5px moving-item-two"
                />
              </div>
              <div className="shape size-150px top-right">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd58e6108cee0fe273569e_face-without-mouth-cloudly-x-webflow-templates.png"
                  alt=""
                  className="moving-item-one"
                />
              </div>
              <div className="shape size-120px bottom-right">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd58f11a50dcb89eccdca7_face-with-hand-over-mouth-cloudly-x-webflow-templates.png"
                  alt=""
                  className="blur-1-5px moving-item-one"
                />
              </div>
            </div>
            <div className="text-center">
              <div className="inner-container _580px center">
                <div className="z-index-1">
                  <div
                    data-w-id="deb09750-b7ba-c3f8-81c8-980638151f08"
                    className="md:style-1"
                  >
                    <img
                      src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5e7701fb94153dfad7d0_face-with-peeking-eye-cloudly-x-webflow-templates.png"
                      alt=""
                      className="shape position-static size-114px"
                    />
                    <img
                      src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5e83ccbf426db687ad63_speak-no-evil-monkey-cloudly-x-webflow-templates.png"
                      alt=""
                      className="shape position-static size-114px"
                    />
                    <img
                      src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5e8fbb10486d78f54f05_shushing-face-cloudly-x-webflow-templates.png"
                      alt=""
                      className="shape position-static size-114px"
                    />
                  </div>
                  <div
                    data-w-id="e57eda69-f44d-5edd-2a08-181d4468ccbf"
                    className="md:style-1"
                  >
                    <h1 className="display-1">Coming soon</h1>
                    <p className="mg-bottom-48px">
                      Lorem ipsum dolor sit consectetur elit aliquam purus sit
                      amet luctus venenatis, lectus magna purus non consectetur
                      dolor sit amet.
                    </p>
                    <div className="form-container">
                      <div className="mg-bottom-16px w-form">
                        <form
                          id="wf-form-Coming-Soon-Newsletter"
                          name="wf-form-Coming-Soon-Newsletter"
                          data-name="Coming Soon Newsletter"
                          method="get"
                          data-wf-page-id="63dd3c718753209766729d2a"
                          data-wf-element-id="ea94fae7-c17b-caae-7783-1fea3b4d89ad"
                        >
                          <div className="position-relative">
                            <input
                              type="email"
                              className="input button-inside w-input"
                              maxLength={256}
                              name="Email"
                              data-name="Email"
                              placeholder="Enter your email"
                              id="Email"
                            />
                            <input
                              type="submit"
                              value="Subscribe"
                              data-wait="Please wait..."
                              className="btn-primary inside-input default white-mb w-button"
                            />
                          </div>
                        </form>
                        <div className="success-message black-message w-form-done">
                          <div className="flex-horizontal success-message-horizontal">
                            <div>Thanks for joining our newsletter.</div>
                          </div>
                        </div>
                        <div className="error-message w-form-fail">
                          <div>
                            Oops! Something went wrong while submitting the
                            form.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-container _300px center">
                      <div className="icon-list-item-wrapper center left-tablet">
                        <div className="mg-right-12px">
                          <img
                            src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5783192bfc553e0aa5cc_envelope-cloudly-x-webflow-templates.png"
                            alt=""
                            className="image-size _32px"
                          />
                        </div>
                        <div className="text-200 bold color-neutral-700">
                          Subscribe to our newsletter
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <div className="container-default w-container">
            <div
              data-w-id="25818af3-6e01-e5f9-d993-ed23bde7b70a"
              className="footer---bottom v3"
            >
              <a
                id="w-node-_25818af3-6e01-e5f9-d993-ed23bde7b70b-bde7b708"
                href="#"
                className="footer-logo-wrapper mg-bottom-0 w-inline-block"
              >
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63e68a8b717bf4b59a5452de_logo-cloudly-x-webflow-template.png"
                  alt="Tej Life - Logo"
                  className="footer-logo"
                />
              </a>
              <div>
                <p className="mg-bottom-0 color-neutral-700">
                  Copyright © TEJ LIFE | Connect{" "}
                  <Link to="mailto:hello@tej.life">
                    Hello@Tej.Life
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
