/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AntCloudOutlined,
  ArrowRightOutlined,
  LinkOutlined,
  RiseOutlined,
  ShopFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import EmailGet from "src/components/EmailGet";
import { PagesMeta } from "src/types/PagesMeta";
import RoutingLinks from "src/utils/RoutingLinks";
import SEOTags from "src/utils/SEOTags";

const Products = () => {
  return (
    <>
    <SEOTags title={PagesMeta.products.title} description={PagesMeta.products.description} type={PagesMeta.products.type} twitterDes={PagesMeta.products.twitterDes} pageUrl={PagesMeta.products.pageUrl}/>
      <div className="py-20 ">
        <div className="container-default w-container">
          <div className="mb-11 md:style-1">
            <div className="flex-horizontal text-center mg-bottom-8px">
              <img
                src="https://assets.website-files.com/63dbcee746674134a00f2de6/63e188c34f2f9ff8a01149ba_stars-cloudly-x-webflow-template.png"
                alt=""
                className="size-64px _28px-mbp"
              />
              <h1 className="display-1 mg-bottom-0">Our Products</h1>
              <img
                src="https://assets.website-files.com/63dbcee746674134a00f2de6/63e188ad73b138595e3060b5_star-cloudly-x-webflow-template.png"
                alt=""
                data-w-id="72bcacf2-b813-f8fa-42d5-16a7c86d8972"
                className="size-64px _28px-mbp"
              />
            </div>
            <div className="inner-container _708px center">
              <p className="text-center mg-bottom-0">
                <b>Kartlify, Ecommerce Platform by Tej Life</b> & <b>ERP by Tej Life</b> are the two SaaS products to make your sales skyrocket and automate your business without hassle.<br />
                {/* Tej Life provides variety of <Link to={RoutingLinks.website.services}>services- view all.</Link> */}
                
              </p>
            </div>
          </div>

          <div className="w-layout-grid grid-2-columns features-grid-1-cols-v1">
            <Link
              className="feature-card box-one w-inline-block !bg-white !items-start boxShadow !pb-0 !grid-cols-none !h-auto"
              to='https://kartlify.com/'
            >
              <div className="!mb-8">
                <div className="headline">
                  <p className="font-medium">Tej Products</p>
                </div>
                <h2 className="mg-bottom-6px mt-9 text-2xl md:text-3xl">
                  <ShopFilled /> Kartlify - Ecommerce Platform{" "}
                </h2>
                <div className=" ">
                  <p className="mg-bottom-0 text-sm md:text-base mt-4">
                    Launch your eCommerce store with our pre-set, lightning-fast
                    software. It`s easy to set up and manage your orders &
                    customers, help you generate more revenue with AI-curated
                    Analytics.
                  </p>
                  <p>Have a look at the features</p>
                </div>
                <div className="learn text-base mt-8 py-2">
                  <Link
                    to="https://kartlify.com"
                    className="btn-primary small w-button"
                  >
                    <span className="qr-code"></span>
                    Beta launched <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </Link>
            <Link
              className="feature-card box-one w-inline-block !bg-white !items-start boxShadow !pb-0 !grid-cols-none !h-auto"
              to={RoutingLinks.website.products}
            >
              <div className=" !mb-8">
                <div className="headline">
                  <p className="font-medium">Tej Products</p>
                </div>
                <h2 className="mg-bottom-6px mt-9 text-2xl md:text-3xl">
                  <AntCloudOutlined /> CRM + ERP Dashboard{" "}
                </h2>
                <div className=" ">
                  <p className="mg-bottom-0 text-sm md:text-base mt-4">
                    Leverage enterprise-level software to manage your business`s
                    internal operations. Reports and analytics generated by
                    Artificial Intelligence. Plan. Pipeline. Perform.
                  </p>
                </div>
                <div className="learn text-base mt-8 py-2">
                  <Link
                    to={RoutingLinks.website.products}
                    className="btn-primary small w-button"
                  >
                    <span className="qr-code"></span>
                    Launching soon<ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="w-layout-grid grid-2-columns features-grid-1-cols-v1 mt-10"
            style={{ gridTemplateColumns: "1fr" }}
          >
            <Link
              className="feature-card box-one w-inline-block !bg-white !items-start boxShadow !pb-0 !grid-cols-none !h-auto"
              to={RoutingLinks.website.products}
            >
              <div className=" !mb-8">
                <div className="headline">
                  <p className="font-medium">Tej Products</p>
                </div>
                <h2 className="mg-bottom-6px mt-9 text-2xl md:text-3xl">
                  <RiseOutlined /> DORT Food Management Software{" "}
                </h2>
                <div className=" ">
                  <p className="mg-bottom-0 text-sm md:text-base mt-4">
                    Powerful & Simple restaurant/club POS software to manage
                    your restaurant billing, KOTs, inventory, online order,
                    menu, and customers.
                  </p>
                </div>
                <div className="learn text-base mt-8 py-2">
                  <Link
                    to={RoutingLinks.website.products}
                    className="btn-primary small w-button"
                  >
                    <span className="qr-code"></span>
                    Launching soon <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </Link>
          </div>
          <div className="rounded-2xl mt-20 flex gap-4 flex-col md:flex-row bg-[#f8f9fd] px-5 py-4 md:px-10 md:py-8">
            {/* <ConnectCallOutWidget /> */}
            <h1 className="text-xl md:text-3xl md:w-2/3">
              Our products offer AI-powered dashboards to support your business
              and increase your revenue upto 10x 🚀
            </h1>
            <div className="form md:w-1/2">
              <EmailGet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
