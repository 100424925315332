import RoutingLinks from "src/utils/RoutingLinks";

export interface SEOTagsProps {
  title: string;  //title of the page
  description: string;
  type?: string;  //page or article or news
  twitterDes?:string;   //used for both meta and x
  pageUrl?: string;
}

export const PagesMeta = {
  home: {
    title: 'TEJ LIFE - A Tech Company. AI-Saas based company having offices in Jaipur & Delhi, India, having clients all over the world for SaaS Platforms.',
    description: 'Tej Life - One of the best Saas Based Company out there in market which provides systems like like Ecommerce, ERP Solutions, leveraging advanced technologies. Making world a better place using the advanced tech, providing cutting egde technologies. ERP Solutions, Blockchain Systems, Web3, AI, ML & Complex development Work.  If you are looking to take your company to the next level, this is the company you are looking for.',
    type: 'page',
    twitterDes: 'Tej Life | Specializing in SaaS solutions, complex software development, and leveraging advanced technologies.',
    pageUrl: `https://tej.life${RoutingLinks.website.home}`
  },
  about: {
    title: 'About Tej Life | SaaS Platforms ERP, Management ',
    description: 'Tej Life founded in 2020 with a vision of providing the best in class SaaS platforms like Ecommerce, ERP, POS, Management Systems to Businesses & End Users. Tej Life have a very dynamic team of young professionals driven by a passion for creating cutting-edge tech products.',
    type: 'page',
    twitterDes: 'Tej Life founded in 2020 with a vision of providing the best in class SaaS platforms like Ecommerce, ERP, POS, Management Systems to Businesses & End Users.',
    pageUrl: `https://tej.life${RoutingLinks.website.ourwork}`
  },
  contact: {
    title: 'Contact - Tej Life, A Tech Company ',
    description: 'Are you tired of Buggy, Slow Systems? Need SaaS platform your business? Contact Tej Life, currently operates from Jaipur and Delhi. Let`s connect and explore the exciting possibilities that lie at the intersection of technology and business',
    type: 'page',
    twitterDes: 'Let`s connect and explore the exciting possibilities that lie at the intersection of technology and business',
    pageUrl: `https://tej.life${RoutingLinks.website.contact}`
  },
  work: {
    title: 'Clients Work by Tej life',
    description: 'Explore the work/projects done by Tej Life, Tej Life have so far. projects that have been built from scratch at Tej Life for our clients. Along with these, we offer SaaS Solutions to cater Business needs.',
    type: 'page',
    twitterDes: 'Explore the work/projects done by Tej Life, Tej Life have so far. projects that have been built from scratch at Tej Life for our clients. Along with these, we offer SaaS Solutions to cater Business needs.',
    pageUrl: `https://tej.life${RoutingLinks.website.contact}`
  },
  team: {
    title: 'Team Section at Tej Life, A Tech Company',
    description: 'Meet our amazing team, Comprising talented individuals from diverse backgrounds, including software development, design, data analytics, and project management. We take pride in being the epitome of a top-tier team.',
    type: 'page',
    twitterDes: 'Introducing Our Exceptional Team: Comprising Talent from Flipkart, Amazon, Stripe, and Merkle Inc. We take pride in being the epitome of a top-tier team.',
    pageUrl: `https://tej.life${RoutingLinks.website.team}`
  },
  products: {
    title: 'AI-SaaS Products by Tej Life | Revolutionised Software & Systems',
    description: 'Tej Life offers subscription based SaaS products designed to streamline your business operations and boost your revenue by up to 10x. Our suite includes enterprise-level software for Ecommerce, ERP, Point of Sale, Restaurant Management, and Book Management.',
    type: 'page',   
    twitterDes: 'Tej Life offers SaaS products designed to streamline your business operations and boost your revenue by up to 10x. Our suite includes enterprise-level software for Ecommerce, ERP, Point of Sale, Restaurant Management, and Book Management.',
    pageUrl: `https://tej.life${RoutingLinks.website.products}`
  },
  services: {
    title: 'Services by Tej Life - AI, Machine Learning, Enterprise Level Systems Architecture Designs',
    description: 'Tej offers free/subscription & permanent solutions based on the client`s requirements. Tej Life offers enterprise-level systems architecture designs and products that help you grow your business up to 10x. We work on engineering and mathematics. ',
    type: 'page',
     
    twitterDes: 'At the intersection of technology innovation and stunning UI/UX design, we create seamless experiences that captivate users and drive business success.',
    pageUrl: `https://tej.life${RoutingLinks.website.services}`
  },
  careers: {
    title: 'Jobs at Tej Life | Work at Tej Life ',
    description: 'Be part of the next-gen tech agency Comprising talented individuals from diverse backgrounds, including software development, design, data analytics, and project management. From flexible work arrangements and ongoing learning opportunities to wellness programs and team-building adventures, we go the extra mile to ensure our team members thrive both personally and professionally.',
    type: 'page',
    twitterDes: 'Together, we combine our expertise, creativity, and dedication to tackle complex challenges and create impactful digital experiences that make a difference in the tech industry. Comprising Talent from Flipkart, Amazon, Stripe, and Merkle Inc. We take pride in being the epitome of a top-tier team.',
    pageUrl: `https://tej.life${RoutingLinks.website.careers}`
  },
}


  