/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoBlack from "../../assets/img/logo-black-removebg-preview.png"
import newLogo from "../../assets/img/tejlife_newlogo.png"
import newSVGLogo from "../../assets/img/tejlife_SVG_logo_2.svg"
import RoutingLinks from "src/utils/RoutingLinks";

import crm from "../../assets/img/sale.png";
import ecom from "../../assets/img/new/Shop_Bag.png"
import dot from "../../assets/img/dot.png"


import { AntCloudOutlined, CloseOutlined, CustomerServiceFilled, ForwardOutlined, MenuOutlined, ShopFilled, ToolFilled } from "@ant-design/icons";

const NavBarMain = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBarMenu = () => {
    setIsOpen(!isOpen);
  }
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");  // ['', '/path_after_domain', 'next_path']
  return (
    <>
      <div className="bg-gradient---hero !fixed top-12 w-full z-10 drop-shadow-sm">
        <div
          data-w-id="3e4eae5f-c9fd-c051-dc9f-9317b4043bae"
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="header-wrapper w-nav"
        >
          <div className="container-default w-container">
            <div
              data-w-id="9bfc3624-ddf6-c21c-70b8-dc1c9c6e78b0"
              className="header-content-wrapper"
            >
              <Link
                to={RoutingLinks.website.home}
                aria-current="page"
                className="header-logo-link w-nav-brand w--current"
              >
                <img
                  // src="https://assets.website-files.com/63dbcee746674134a00f2de6/64078ac15c49a678393b3156_logo-cloudly-webflow-ecommerce-template.png"
                  src={newSVGLogo}
                  alt="Tej Life - Logo"
                  className="header-logo !w-14"
                />
              </Link>
              <div className="header-middle right">
                <nav
                  role="navigation"
                  className="header-nav-menu-wrapper w-nav-menu"
                >
                  <ul role="list" className="header-nav-menu-list gap-2">
                    <li className={`header-nav-list-item middle ease-in-out delay-50 hover:scale-105 hover:bg-[#c7deff] duration-300 rounded-xl ${splitLocation[1] === 'home' || splitLocation[1] === "" ? `active` : ''}`}>
                      <Link
                        to={RoutingLinks.website.home}
                        aria-current="page"
                        className={`header-nav-link w-nav-link w--current`}
                      >
                        Home
                      </Link>
                    </li>
                    <li className={`header-nav-list-item middle transition ease-in-out delay-50 hover:scale-105 hover:bg-[#ffccdc] duration-300 rounded-xl ${splitLocation[1] === 'about' ? `active` : ''}`}>
                      <Link to={RoutingLinks.website.about} className="header-nav-link w-nav-link">
                        About
                      </Link>
                    </li>
                    <li className={`header-nav-list-item middle ease-in-out delay-50 hover:scale-105 hover:bg-[#fcd9ca] duration-300 rounded-xl ${splitLocation[1] === 'projects' ? `active` : ''}`}>
                      <Link to={RoutingLinks.website.ourwork} className="header-nav-link w-nav-link ">
                        Our Work
                      </Link>
                    </li>
                    <li className={`header-nav-list-item middle megamenu ease-in-out delay-50 hover:scale-105 hover:bg-[#c7f6d1] duration-300 rounded-xl ${splitLocation[1] === 'products' ? `active` : ''}`}>
                      <Link to={RoutingLinks.website.products} className="header-nav-link w-nav-link">
                        Products
                      </Link>
                      <nav className="dropdown-column-wrapper w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                      <div className="dropdown-pd">
                        <div className="">
                            <div>
                              <Link to={RoutingLinks.website.products} className="flex items-center subtitle v1 no-underline !text-lg gap-2"><ShopFilled/> <span>ECommerce Platform</span></Link>
                              <p className="text-xs">Launch your Ecommerce Store with our preset theme options and flexible dashboard</p>
                              <Link to={RoutingLinks.website.products} className="flex items-center subtitle v1 no-underline !text-lg gap-2"><AntCloudOutlined/> <span>CRM Software</span></Link>
                              <p className="text-xs">Manage your Customers, generate more sales with Advanced Analytics</p>
                              <Link to={RoutingLinks.website.products} className="flex items-center subtitle v1 no-underline !text-lg gap-2"><img src={dot} className="w-7"/> <span>DORT Food</span></Link>
                              <p className="text-xs">A comprehensive solution for managing your restaurant and club business needs.</p>
                            </div>
                        </div>
                      </div>
                      </nav>
                      
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-right-side">
                <Link
                    to={RoutingLinks.website.contact}
                    className="btn-primary small hidden-on-tablet w-button"
                  >
                    <span className="qr-code"></span>
                    &nbsp;Contact us
                  </Link>
              </div>
              <div className="mobile-menu header-right-side lg:!hidden" onClick={handleBarMenu}>
                {isOpen ? <CloseOutlined className="text-3xl"/> : <MenuOutlined className="text-3xl"/>}
              </div>
            </div>
            {isOpen && <>
              <div className="flex justify-end">
                <div className="mt-4 flex flex-nowrap flex-col justify-end text-right">
                  <Link to={RoutingLinks.website.home} className={`block no-underline text-xl font-semibold mb-2 ${splitLocation[1] === 'home' || splitLocation[1] === "" ? `active` : ''}`} onClick={handleBarMenu}>Home</Link>
                  <Link to={RoutingLinks.website.about} className={`block no-underline text-xl font-semibold mb-2 ${splitLocation[1] === 'about' ? `active` : ''}`} onClick={handleBarMenu}>About</Link>
                  <Link to={RoutingLinks.website.ourwork} className={`block no-underline text-xl font-semibold mb-2 ${splitLocation[1] === 'projects' ? `active` : ''}`} onClick={handleBarMenu}>Work</Link>
                  <Link to={RoutingLinks.website.products} className={`block no-underline text-xl font-semibold mb-2 ${splitLocation[1] === 'products' ? `active` : ''}`} onClick={handleBarMenu}>Our Products</Link>
                  <Link to={RoutingLinks.website.contact} className={`block no-underline text-xl font-semibold mb-2 ${splitLocation[1] === 'contact' ? `active` : ''}`} onClick={handleBarMenu}>Contact</Link>
                </div>
              </div>
            
            </>}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarMain;
